import React from 'react'
import Header from '../inc/Header'
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
function OfficeB() {
    return (
        <div>
            <Header />
            <br />
            <br />
            <br />
            <br />

            <ScrollToTop smooth top="100" color="light" />
            <div className='bestberhamporeschool'>
                <div className="album py-1">
                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" style={{ color: "darkblue" }} href="#">Home</Link></li>

                                <li className="breadcrumb-item active" aria-current="page">Office Bearers <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                    <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                </svg> </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />
            <div className="album py-1">
                <div className="container">
                    <div className='colortextoffice'>
                        <h2>Office Bearers</h2>
                    </div>
                </div>
            </div>
            <div className="album py-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 mt-3">
                            <div className="card" >
                                <div className="card-body">
                                    <center>
                                        <div className='colordesignation'>

                                            <h5 className="card-title"><b>  Hon'ble President  </b></h5>
                                        </div>
                                        <h5 className="card-text">Dr. Dipayan Tarafdar</h5>
                                        <a href='tel: 9434148327'>Call: +91 9434148327</a>
                                    </center>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-3 mt-3">
                            <div className="card" >
                                <div className="card-body">
                                    <center>
                                        <div className='colordesignation'>
                                            <h5 className="card-title mb-3"><b>Vice President:           </b></h5>
                                        </div>

                                        <h5 className="card-text mb-1">Kasem Mallick         </h5>
                                        <a className='mb-4' href='tel: 9434530745'>Call: +91 9434530745 </a>

                                        <h5 className="card-text mt-3">Sumana Mandal               </h5>

                                        <h5 className="card-text">Subhamoy Mazumder               </h5>

                                    </center>
                                </div>

                            </div>
                        </div>

                        <div className="col-md-3 mt-3">
                            <div className="card" >
                                <div className="card-body">
                                    <center>
                                        <div className='colordesignation'>
                                            <h5 className="card-title"><b>Secretary:                         </b></h5>
                                        </div>
                                        <h5 className="card-text"> Sanjoy Sarkar    </h5>
                                        <h5 className="card-text"> Shibaji Sarkar              </h5>
                                    </center>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-3 mt-3">
                            <div className="card" >
                                <div className="card-body">
                                    <center>
                                        <div className='colordesignation'>
                                            <h5  className="card-title"><b>Assistant Secretary:                                        </b></h5>
                                        </div>
                                        <h5 className="card-text mb-3">Daisy Paul                                        </h5>

                                        <h5 className="card-text">Arijit Gupta                                        </h5>
                                        <a href='tel: 9733966161'>Call: +91 9733966161</a>
                                    </center>
                                </div>

                            </div>
                        </div>
                  
                        <div className="col-md-3 mt-3">
                            <div className="card" >
                                <div className="card-body">
                                    <center>
                                    <div className='colordesignation'>
                                        <h5  className="card-title"><b>Treasurer:                                        </b></h5>
                                        </div>
                                        <h5 className="card-text"> Anirban Chandra                        </h5>
                                        
                                    </center>
                                </div>

                            </div>
                        </div>
                     
                        <div className="col-md-3 mt-3">
                            <div className="card" >
                                <div className="card-body">
                                    <center>
                                    <div className='colordesignation'>
                                        <h5  className="card-title"><b> Assistant Treasurer:                                       </b></h5>
                                        </div>
                                        <h5 className="card-text"> Arindam Sen                                    </h5>
                                        {/* <a href='tel: 9733966161'>Call: +91 9733966161</a> */}
                                    </center>
                                </div>

                            </div>
                        </div>
                   
                        <div className="col-md-3 mt-3">
                            <div className="card" >
                                <div className="card-body">
                                    <center>
                                    <div className='colordesignation'>
                                        <h5  className="card-title"><b>Social Intiatives Communication and Media   </b></h5>
                                        </div>
                                        <h5 className="card-text">Arindam Mallick</h5>
                                        <h5 className="card-text">Rajorshi Mondal</h5>
                                        <h5 className="card-text">Dibyojyoti Sarkar</h5>
                                        <h5 className="card-text">Dr. Ananya Roy</h5>
                                        <h5 className="card-text">Sayan Chaki</h5>
                                        <h5 className="card-text">Devdoot Roy Chowdhury</h5>
                                    </center>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />

        </div>
    )
}

export default OfficeB