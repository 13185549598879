// import React from 'react'
// import {Link} from "react-router-dom";

// import "./Navbar.css";

// function Navbar() {
//   return (
//     <div>
//       <div className='fixed-top'>
//         <nav className="navbar navbar-expand-lg navbar-light bg-light shadow">
//   <div className="container-fluid">
//     <Link to="/">
//     <a className="navbar-brand" href="#"><b>MISSA</b></a></Link>
//     <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
//       <span className="navbar-toggler-icon"></span>
//     </button>
//     <div className="collapse navbar-collapse" id="navbarSupportedContent">
//       <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
//         <li className="nav-item">
//           <Link to="/" style={{color:"maroon"}} className="nav-link active" aria-current="page" href="#">HOME</Link>
//         </li>
//         <li className="nav-item">
//           <Link to="/about-us" style={{color:"maroon"}} className="nav-link" href="#">ABOUT US</Link>
//         </li>
//         <li className="nav-item">
//           <Link to="/Memberlist" style={{color:"maroon"}} className="nav-link" >MEMBER</Link>
//         </li>
//         <li className="nav-item dropdown">
//           <a style={{color:"maroon"}} className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
//             COMUNITY
//           </a>
//           <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
//             <li><a className="dropdown-item" href="DrDipayan_Tarafdar">PRESIDEND SPEECH</a></li>
//             <li><a className="dropdown-item" href="#">OUR OFFICO</a></li>
//             <li><hr className="dropdown-divider"/></li>
//             <li><a className="dropdown-item" href="#">SECRETARY</a></li>
//           </ul>
//         </li>
//         <li className="nav-item">
//           <Link to="/Gallery" style={{color:"maroon"}} className="nav-link" href="#">GALLERY</Link>
//         </li>
//         <li className="nav-item">
//           <Link to="/contact-us" style={{color:"maroon"}} className="nav-link" href="#">CONTACT US</Link>
//         </li>
//       </ul>

//       <button className="SIGNUP me-3" type="submit">SING UP</button>
//         <button className="login" type="submit">LOGIN</button>

//     </div>
//   </div>
// </nav>
// </div>
//     </div>
//   )
// }

// export default Navbar





















import React, { useEffect, useState } from 'react';
import { Link, useNavigate, NavLink } from 'react-router-dom';

import DropdownButton from 'react-bootstrap/DropdownButton'
import './Header.css'
// import { Button,Dropdown} from 'react-bootstrap';
import { Button, Navbar, Dropdown, Container, Nav, Form, FormControl, NavDropdown } from 'react-bootstrap';
import { useUserAuth } from '../../contexts/UserAuthContext'

import Spinner from 'react-bootstrap/Spinner';



function Header(props) {

  let time = new Date().toLocaleTimeString();
  const [ctime, setCtime] = useState(time);

  const UpdateTime = () => {
    time = new Date().toLocaleTimeString();
    setCtime(time);
  };

  setInterval(UpdateTime, 1000);



  const navigate = useNavigate()
  const { user, logOut } = useUserAuth()
  const [photoURL, setPhotoURL] = useState()

  //const emailVerified = user.emailVerified;
  const handleLogOut = async () => {
    try {
      await logOut()
      window.location.reload()
      navigate("/")
    } catch (err) {

    }
  }

  useEffect(() => {
    if (user?.photoURL) {
      setPhotoURL(user.photoURL)
    }

  }, [user])


  return (
    <>
      {/* <div className="mains container-fluid"> */}
      <div className="fixed-top">
    
        <div className="bestschoolinberhampore">
          <div className="album py-2">

            <div className="container">

              <div className="row ">

                <div className="col-md-5">
                  <div className="d-flex justify-content-between align-items-center">


                    <a href='tel:  9434101829'> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-minus" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5" />
                      <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                    </svg> +91  94341 01829</a>
                    <a style={{ color: "green" }} href='Join_membership'>Join Membership</a>

                  </div>

                </div>

                <div className="d-flex justify-content-between align-items-center">





                </div>
              </div>
            </div>
          </div>
        </div>

        <Navbar bg="white shadow" expand="lg">
          <Container fluid>
            <Navbar.Brand>
              <Link to="/" className='me-0'>
              <div className='colornav'>
                <a className="navbar-brand" href="#"><b>MISAA</b></a>
                </div>
                {/* <img src={Logo} height="40px" width="75px" alt="" /> */}
              </Link>

            </Navbar.Brand>
            <div className="text-end d-grid gap-2 d-md-flex justify-content-md-end">

              {!user ? (

                <div>

                  <Link to="/Login">
                    <button type="button" className="buttonlogin me-4">LOGIN <Spinner animation="grow" variant="light" size="sm" /></button>
                  </Link>

                </div>
              ) :
                <>

                  <Dropdown className='dropdown'>
                    <Dropdown.Toggle variant="none" id="dropdown-basic">
                      <img className='userphoto' src={user.photoURL} alt="" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>

                      <Dropdown.Item>
                        <Button onClick={() => { navigate(`/UserDashboard/${user.uid}`) }} variant='secondary btn-sm'><small>Dashboard</small></Button>
                      </Dropdown.Item>

                      <Dropdown.Item>
                        <Button onClick={() => { navigate(`/UserDashboard/${user.uid}`) }} variant='light btn-sm'>Upload  </Button>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <Button variant='danger btn-sm' onClick={handleLogOut}><small>LOG OUT</small></Button>
                      </Dropdown.Item>

                    </Dropdown.Menu>

                  </Dropdown>
                </>

              }

            </div>

            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: '290px' }}
                navbarScroll
              >

                <li className="nav-item">
                  <Link to="/" style={{ color: "maroon" }} className="nav-link active" aria-current="page" href="#">HOME</Link>
                </li>
                <li className="nav-item">
                  <Link to="/about-us" style={{ color: "maroon" }} className="nav-link" href="#">ABOUT US</Link>
                </li>


                <li className="nav-item">
                  <Link to="/Memberlist" style={{ color: "maroon" }} className="nav-link" >MEMBER</Link>
                </li>
                <li className="nav-item dropdown">
                  <a style={{ color: "maroon" }} className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    COMUNITY
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><a className="dropdown-item" href="office-bearers">Office Bearers</a></li>
                    {/* <li><a className="dropdown-item" href="#">OUR OFFICO</a></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><a className="dropdown-item" href="#">SECRETARY</a></li> */}
                  </ul>
                </li>

                <li className="nav-item">
                  <Link to="/Gallery" style={{ color: "maroon" }} className="nav-link" href="#">GALLERY</Link>
                </li>

                <li className="nav-item">
                  <Link to="#" style={{ color: "maroon" }} className="nav-link" href="#">LIFETIME MEMBER</Link>
                </li>
                <li className="nav-item">
                  <Link to="/Memories" style={{ color: "maroon" }} className="nav-link" href="#">MEMORIES </Link>
                </li>
                <li className="nav-item">
                  <Link to="/contact-us" style={{ color: "maroon" }} className="nav-link " href="#">CONTACT US</Link>
                </li>

















              </Nav>
              <a href='tel: +91 9733966161'>Technical Helpline </a>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

    </>

  );
}

export default Header;



