import React from 'react'

import { Link } from "react-router-dom";

import ScrollToTop from "react-scroll-to-top";
// import ReactWhatsapp from "react-whatsapp";
import AddFeedback from '../AddFeedback';
import Header from '../inc/Header';




function Contactus() {
    return (
        <div>
            <Header />
            <br />
            <br/>
            <br/>
            <br />
          
            <ScrollToTop smooth top="100" color="light" />
            <div className='bestberhamporeschool'>
                <div className="album py-1 ">
                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" style={{ color: "darkblue" }} href="#">Home</Link></li>

                                <li className="breadcrumb-item active" aria-current="page">Contact Us <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                    <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                </svg> </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>


            <div className="album py-4">
                <div className="container">

                    <div className="row">
                        <div className="col-md-7 mt-3">


                            <div className="card-body">
                                <h4 className="card-title mb-3">Main Office</h4>
                                <p className='mb-0'>Address: </p>
                                <p className="card-text mb-0">Mary Immaculate School Alumini Association <br />96 K.K. Banerjee Road, Gorabazar</p>
                                <p className="card-text">Berhampore, West Bengal, PIN: 742101</p>
                                <a href="tel: +91 9434530745">
                                    <p style={{ color: "darkBlue" }} className="mb-2" > Helpline:   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
                                        <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z" />
                                    </svg> +91 9434530745
                                    </p> </a>
                                <a href="tel: +91 9434101829">
                                    <p style={{ color: "darkBlue" }} className="mb-2" > Helpline:   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
                                        <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z" />
                                    </svg> +91  94341 01829
                                    </p> </a>
                                <a href='mailto: official@misaa.org'>
                                    <p>Email: official@misaa.org.in</p>
                                </a>
                                <br />
                                {/* <h4><b>Office Bearers</b></h4>

                                <h6>   <b>President:</b> <br />
                                    Dr. Dipayan Tarafdar<br />
                                    <a href='tel: 9434148327'>Call: +91 9434148327</a></h6>
                                <h6>

                                    <b>Vice President:</b> <br />
                                    Mr. Sanjoy Kumar Sarkar<br />
                                    <a href='tel: +91 9434108762'>Call: +91 9434108762</a>
                                </h6>

                               
                                <h6><b>Secretary:</b><br />
                                    Mr. Kasem Mallick<br />
                                    <a href='tel: +91 9434530745'>Call: +91 9434530745</a>
                                </h6>
<h6><b>Jt Secretary:</b> <br/>
Mr. Shibaji Sarkar <br/>
<a href='tel; +91 9434101829'>Call: +91 9434101829</a>
</h6>
                                <h6><b>Update soon:</b><br />
                                    Mr. Arijit Gupta <br />
                                    <a href='tel: +91 9733966161'>Call: +91 9733966161</a>
                                </h6> */}



                            </div>

                        </div>
                        <div className="col-md-5 mt-3">

<AddFeedback/>
                        </div>
                    </div>
                </div>
            </div>
            <br />
         
            <div className="album py-2">
        <div className="container">
          <div className="row">
            <iframe className="iframe-fluid mb-3" height="340" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Mary%20Immaculate%20School,%2096,%20K.K.%20Banerjee%20Road,%20Raninagar,%20Gora%20Bazar,%20Berhampore,%20West%20Bengal%20742101%20+(Mary%20Immaculate%20School,%2096,%20K.K.%20Banerjee%20Road,%20Raninagar,%20Gora%20Bazar,%20Berhampore,%20West%20Bengal%20742101)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
          </div>
        </div>
      </div>
      <br/>
      <br/>

        </div>
    )
}

export default Contactus