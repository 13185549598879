import React from 'react'
import Header from '../inc/Header'
import Gallerypage from './Gallerypage'
import {Link} from "react-router-dom";

function Galleryhome() {
  return (
    <div>
        <Header/>
        <br/>
        <br/>
        <br/>

        <br/>
       
        <div className="bestberhamporeschool">

<div className="album py-1">

    <div className="container mt-3">
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link style={{ color: "darkblue" }} to="/" > Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Gallery</li>
            </ol>
        </nav>
    </div>
</div>
</div>
        <Gallerypage/>
    </div>
  )
}

export default Galleryhome