import React from 'react';



import MRINDIANCODER from "../images/mr.indiancoder.jpg";
import "./Footer.css";
function Footer() {
    return(
        
    
      <div className="berhamporeschool">
         <br></br>
         <br/>
        

    <div className="album py-3">

        <div className="container">

            <div className="row">
            <div className="col-md-4">
              
                 <h6><b></b>MAIN OFFICE</h6>
                   <hr/>
                    
                     <ul className="list-unstyled">
                    <p className="card-text mb-1"> Address: </p>
                     <h6 className="card-text mb-2"> Mary Immaculate School Alumini Association</h6>
                     <p className="card-text mb-1">96 K.K. Banerjee Road, Gorabazar</p>
                     <p className="card-text mb-2"> Berhampore, West Bengal, PIN: 742101</p>
                   
<a className="mb-3" style={{color:"white"}} href="tel: +91 9434530745"  > Phone:    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z"/>
</svg> (+91) 9434530745  / <a  style={{color:"white"}}  href='tel:  9434101829'> (+91)  9434101829</a></a>
<br/>

Email: <a style={{color:"white"}} href="mailto:  official@misaa.org.in" > Email: official@misaa.org.in</a> 

          </ul>
          </div>

          <div className="col-md-3">
                     <h6>QUICK LINK</h6>
                     <hr/>
                     <ul className="list-unstyled">
                    
                     
            
           
            <a href='Memberlist'  style={{color:"white"}} >
            <li className="mb-2"><a  style={{color:"white"}} ><small>MEMBER</small></a></li></a>
            <a href='Join_membership'  style={{color:"white"}} >
            <li className="mb-2"><a  style={{color:"white"}} ><small>MEMBERSHIP PLAN</small></a></li></a>
            <a href='gallery'  style={{color:"white"}} >
            <li className="mb-2"><a  style={{color:"white"}} ><small>GALLERY</small></a></li></a>
         
            
            
          </ul>
                     
                 </div>
                 <div className="col-md-3">
                 
                     <h6>OTHERS LINK</h6>
                     <hr/>

                     <ul class="list-unstyled">
                  
                     <a href='contact-us'  style={{color:"white"}} >
                     <li style={{color:"white"}}  className="mb-2"><small>CONTACT US </small></li></a>
                     <a href='about-us'  style={{color:"white"}} >
                     <li  style={{color:"white"}} className="mb-2"><small>ABOUT US</small></li></a>
                     
                     </ul>
                 </div>
                 <div className="col-md-2">
                 <h6>Social Link</h6>
                   <hr/>
                     <ul className="list-unstyled">
                     <li className="mb-2"> <a href="https://www.facebook.com/misaa.org/" target="_blank"  rel="noopener noreferrer">
                 <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style={{color:"white"}} fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
</svg> <small style={{color:"white"}}> MISAA</small></a> </li>
                           <br/>
<h6 className="mb-1">Legal</h6>
                   <hr className="mb-1"/>
                     <ul className="list-unstyled">
                     <a href='Terms'  style={{color:"white"}} >
                     <li style={{color:"white"}}  className="mb-2"><small>Terms & Condition </small></li></a>
                     <a href='#'  style={{color:"white"}}>
                     <li style={{color:"white"}}  className="mb-2"><small>Privacy </small></li></a>
                     <a href='#'  style={{color:"white"}} >
                     <li style={{color:"white"}}  className="mb-2"><small>Cancellation & Refund </small></li></a>
                     
                      </ul>

            
          </ul>
          
          </div>
       
  

        
          
                 </div>
                 </div>
                 </div>
               
               <center>

                 <button className="btn btn-light me-3" type="submit">Visitor Counter <div align='end'>
               <small>
               <a href="https://www.misaa.org.in" target="_blank" rel="noopener noreferrer">
               <img src="https://hitwebcounter.com/counter/counter.php?page=16146410&style=0006&nbdigits=9&type=page&initCount=300000" title="misaa" Alt="misaa"   border="0" /></a>   
                               </small></div> </button></center>
                 <br></br>


                     
    <div className="album py-1">
        
        <div className="container">

            <div className="row">
            <div className="col-md-3">
    
                 </div>

                 <div className="col-md-5">
                 <p> Copyright © 2025, Mary Immaculate School Alumni Association</p>
                  
                 </div>
                 <div className="col-md-4">
                 <small> Development & Design : <a style={{color:"white"}}  href="https://www.mrindiancoder.com/" target="_blank"  rel="noopener noreferrer"> www.mrindiancoder.com</a> </small>
                <small>  <a style={{color:"white"}}  href="https://www.mrindiancoder.com/" target="_blank"  rel="noopener noreferrer">  </a> </small>
                <a   href="https://www.mrindiancoder.com/" target="_blank" rel="noopener noreferrer"> <img src={MRINDIANCODER} width="75" height="26" alt="mr.indiancoder"/> </a>
                 </div>
                 
                 </div>
                 </div>
                 </div>
                <br></br>

             </div>
             
            
         
         
     
    );
}
export default Footer;