import React from 'react'

import { Link } from "react-router-dom";
import DrDipayanTarafdar from "../images/president_Dr. Dipayan Tarafdar.jpg";
import Header from '../inc/Header';


function DrDipayan() {
    return (
        <div>
            <Header />
            <br />
            <br />
            <br />
            <br />
            

            <div className='bestberhamporeschool'>
                <div className="album py-1">
                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" style={{ color: "darkblue" }} href="#">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Dr. Dipayan Tarafdar<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                    <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                </svg> </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="album py-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mt-3">
                            <div className="card">
                                <img src={DrDipayanTarafdar} className="card-img-top" alt="Dr. Dipayan Tarafdar" />

                            </div>
                        </div>
                        <div className="col-md-8 mt-3">


                            <div className="card-body">
                                <h6 className="card-title textjustify mb-3">Tempus Fugit (time Flies) This expression summarizes my feelings about the limitations but also the many blessings of a presidential term.There is a lot to do and certainly a lot one could do .Here it is important to restrict for us to what can be accomplished.So, while I am honoured to have received your trust to handle association matters I am also very humbled by the sense that perhaps more could have been done.

                                </h6>
                                <h6 className='textjustify mb-3'>Time spent profitably –working with my gifted colleagues on the executive committee dedicated to pressing issues of concern to our association and its members-is what I will recall most when my term of office concludes.Much effort goes on ´behind the scenes´ and as is indicated below ,there has been considerable progess.We are happy that the association is in good hands.</h6>
                                <h6 className='textjustify'>We are very excited to announce the relaunching of our website. Visit us at our same address www.misaa.org but experience a new look, design and fresh content.  We wanted to make website more visually appealing and more user friendly. This new website is interactive and gives better access about us</h6>
                                <h6 className='textjustify'>he primary objectives of our site development effort were focused on aesthetics, simplifying our contents and increasing the visibility of our programs.</h6>
                                <h6 className='textjustify'>Our team has worked hard to put together this website, rich in information, to be used as resource. We pride ourselves on being an organisation dedicated to our alumnus all over the world and look forward to hear any feedback from you.</h6>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />



        </div>
    )
}

export default DrDipayan