import React from 'react'
import Header from '../inc/Header'
import Joinm from './Joinm'
import {Link} from 'react-router-dom';
import ScrollToTop from "react-scroll-to-top";


function Joinpage() {
  return (
    <div>
        <Header/>
        <br/>

        <br/>
        <br/>
        <br/>
      
        <ScrollToTop smooth top="100" color="light"/>
        <div className='bestberhamporeschool'>
            <div className="album py-1">
                <div className="container mt-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" style={{ color: "darkblue" }} href="#">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Membership Page</li>
                        </ol>
                    </nav>
                </div>
            </div>
            </div>
        <br/>
        <Joinm/>
    </div>
  )
}

export default Joinpage